<template>
  <input
    v-model="content"
    class="custom-test-input"
    :size="getInputSize"
    type="text"
    @input="onInput"
  >
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'blur',
  },
  props: {
    value: {
      type: null,
      required: false,
      default: '',
    },
    inputSize: {
      type: [Number, String],
      required: false,
      default: 10,
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  computed: {
    getInputSize() {
      if (this.content.length > this.inputSize) {
        return this.content.length
      }
      return this.inputSize
    },
  },
  mounted() {
    this.$emit('blur', this.value)
  },
  methods: {
    onInput() {
      this.$emit('blur', this.content)
    },
  },
}
</script>

<style lang="scss">
  .custom-test-input {
    margin: 0 10px;
    border: 0;
    border-bottom: 1px solid black;
    background-color: transparent;
  }
  .custom-test-input:focus-visible {
    border: 0;
    border-bottom: 1px solid black;
    outline: none;
  }
</style>
