<template>
  <div
    :id="`${questionId}-${question.id}`"
    :ref="`${questionId}-${question.id}`"
    class="d-flex align-items-start"
  >
    <div
      class="question-text-container ck-content flex-grow-1 w-100"
      v-html="question.task"
    />
    <!-- <v-select
      v-model="content"
      :options="question.options"
      label="content"
      :reduce="opt => opt.key"
      style="min-width: 150px"
      @input="$emit('input', content)"
    /> -->
    <b-form-select
      v-model="content"
      class="flex-shrink-1 flex-grow-0 ml-1"
      style="min-width: 150px; max-width: 150px"
      size="sm"
      @input="$emit('input', content)"
    >
      <b-form-select-option
        v-for="(item, index) in question.options"
        :key="index"
        :value="item.key"
        v-html="item.content"
      />
    </b-form-select>
  </div>
</template>

<script>
import { BFormSelect, BFormSelectOption } from 'bootstrap-vue'

export default {
  components: {
    // vSelect,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: null,
      required: true,
    },
    questionId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  watch: {
    value() {
      this.content = this.value
    },
  },
  mounted() {
    this.content = this.value
    this.$emit('input', this.content || '')
  },
}
</script>

<style lang="scss" scoped>
  .question-text-container p {
    margin: 0
  }
</style>
