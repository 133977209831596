<template>
  <div>
    <div
      class="question-text-container ck-content flex-grow-1 w-100"
      v-html="question.task"
    />
    <label for="answer">
      <h4>
        {{ $t('tests.write_your_answer_below') }}
      </h4>
    </label>
    <b-form-textarea
      id="answer"
      v-model="content"
      rows="15"
      class="mb-2"
      @input="$emit('input', content)"
    />
  </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: null,
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  mounted() {
    console.log('essay')
  },
}
</script>

<style lang="scss" scoped>

</style>
