<template>
  <div>
    <div
      :id="`${questionId}-${question.id}`"
      class="ck-content"
      v-html="question.task"
    />
    <b-form-group>
      <b-form-radio-group
        v-model="content"
        @input="$emit('input', content)"
      >
        <ol
          :style="styleType ? `list-style-type: ${styleType}` : ''"
          :class="[isVertical ? 'd-block':'d-flex']"
        >
          <li
            v-for="(option, index) in question.options"
            :key="index"
            :class="[isVertical ? '': 'mr-2']"
          >
            <b-form-radio
              :value="option.key"
            >
              <span v-html="option.content" />
            </b-form-radio>
          </li>
        </ol>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    isVertical: {
      type: Boolean,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: null,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    questionId: {
      type: Number,
      required: false,
    },
    styleType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  watch: {
    value() {
      this.content = this.value
    },
  },
  mounted() {
    this.content = this.value
    this.$emit('input', this.content || '')
  },
}
</script>

<style>

</style>
