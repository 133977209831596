<template>
  <div class="mx-2">
    <div class="d-flex align-items-center justify-content-between">
      <div
        class="ck-content"
        v-html="questionsBody.condition"
      />
      <!-- <b-button
        v-if="lessonAttachment"
        class="p-50"
        @click="$emit('settingsClick')"
      >
        <feather-icon icon="SlidersIcon" />
      </b-button> -->
    </div>
    <hr>
    <div
      v-if="questionsBody.type === 'g'"
      class="ck-content"
    >
      <component
        :is="gap_content"
        :value="answers"
        @results="result"
      />
    </div>
    <div v-else-if="questionsBody.type === 's'">
      <div
        v-for="(question, index) in questionsBody.body"
        :key="index"
      >
        <!-- <b>{{ index + 1 }}. </b> -->
        <!-- {{ answers[index] }} -->
        <single-choice-type-view
          v-model="answers[index]"
          :question="question"
          :is-vertical="questionsBody.is_vertical"
          :question-id="questionsBody.id"
          :style-type="questionsBody.variant_type"
          @input="answersChanged"
        />
      </div>
    </div>
    <div v-else-if="questionsBody.type === 'm'">
      <div
        v-for="(question, index) in questionsBody.body"
        :key="index"
      >
        <!-- <b>{{ index + 1 }}. </b> -->
        <multiple-choice-type-view
          v-model="answers[index]"
          :question="question"
          :is-vertical="questionsBody.is_vertical"
          :question-id="questionsBody.id"
          :style-type="questionsBody.variant_type"
          @input="answersChanged"
        />
      </div>
    </div>
    <div v-else-if="questionsBody.type === 'a'">
      <div
        v-for="(question, index) in questionsBody.body"
        :key="index"
      >
        <!-- <b>{{ index + 1 }}. </b> -->
        <arbitrary-choice-type-view
          v-model="answers[index]"
          :question="question"
          :question-id="questionsBody.id"
          @input="answersChanged"
        />
      </div>
    </div>
    <div v-else-if="questionsBody.type === 'e'">
      <div
        v-for="(question, index) in questionsBody.body"
        :key="index"
      >
        <!-- <b>{{ index + 1 }}. </b> -->
        <essay-type-view
          v-model="answers[index]"
          :question="question"
          @input="answersChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Vue from 'vue'
import gapFillingTypeView from './gapFillingTypeView.vue'
import singleChoiceTypeView from './singleChoiceTypeView.vue'
import multipleChoiceTypeView from './multipleChoiceTypeView.vue'
import arbitraryChoiceTypeView from './arbitraryChoiceTypeView.vue'
import essayTypeView from './essayTypeView.vue'

Vue.component('gap-filling-type-view', gapFillingTypeView)

export default {
  components: {
    gapFillingTypeView,
    singleChoiceTypeView,
    multipleChoiceTypeView,
    arbitraryChoiceTypeView,
    essayTypeView,
    BButton,
  },
  props: {
    questionsBody: {
      type: [String, Object],
      required: true,
    },
    listStyleType: {
      type: String,
      required: false,
      default: '',
    },
    lessonAttachment: {
      type: Boolean,
      required: false,
      default: false,
    },
    sidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
    savedAnswers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      tasks: '',
      answers: [],
      answerss: {
        id: '142',
        relations: [
          {
            id: 44,
            answers: {
              0: {
                id: 3,
                answers: [
                  'first',
                  'second',
                  '3rd',
                  '4th',
                  '5th',
                  '6th',
                ],
              },
              1: {
                id: 2,
                answers: [
                  [
                    3,
                  ],
                ],
              },
              2: {
                id: 1,
                answers: [
                  3,
                  2,
                  2,
                ],
              },
            },
          },
          {
            id: 45,
            answers: {
              0: {
                id: 7,
                answers: [
                  2,
                  1,
                ],
              },
            },
          },
        ],
      },
      gap_content: '',
    }
  },

  watch: {
    answers() {
      // this.$emit('answersChanged', this.answers)
    },
    sidebar(value) {
      if(value) {
        this.answers = this.savedAnswers
        if (this.questionsBody.type === 'g') {
          this.gap_content = this.getQuestionBody()
        }
      }
    },
    savedAnswers() {
      this.answers = this.savedAnswers
    },
  },

  mounted() {
    this.answers = this.savedAnswers
    if (this.questionsBody.type === 'g') {
      this.gap_content = this.getQuestionBody()
    }
  },

  methods: {
    getQuestionBody() {
      const regex = /@question_gap(?:_(\d+))?/g
      const regForReplace = /@question_gap(?:_(\d+))?/u
      const tasks = this.questionsBody.body[0].task
      const question_matches = this.questionsBody.body[0].task.match(regex)
      let html = ''
      if (this.questionsBody.type === 'g') {
        let repData = tasks
        question_matches?.forEach((element, index) => {
          const a = repData.replace(
            regForReplace,
            `<gap-filling-type-view v-model="val[${element.split('_')[2] - 1 || index}]" @blur="input"/>`,
          )
          repData = a
        })
        html = repData
      }
      return {
        template: `<div>${html}</div>`,
        props: {
          value: {
            type: Array,
            default: () => [],
          },
        },
        mounted() {
          this.val = this.value
        },
        data() {
          return {
            val: this.value,
          }
        },
        methods: {
          input() {
            this.$emit('results', this.val)
          },
        },
      }
    },
    onSwiper(s) {
      console.log(s)
    },
    result(e) {
      this.answers = e
      this.$emit('answersChanged', this.answers)
    },
    answersChanged() {
      this.$emit('answersChanged', this.answers)
    },

  },
}
</script>

<style></style>
